// import React, { useEffect, useState } from "react";
// import SideBar from "./SideBar";
// import Navbar from "./Navbar";
// import { useAuth } from "../AuthContext";
// import { useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import axios from "axios";

// const DriverHelpSupportEdit = () => {
//     const navigate = useNavigate();
//     const { apipath } = useAuth();
//     const { driverHelpSupportId } = useParams();

//     const [formData, setFormData] = useState({
//         helpSupportHeading: '',
//         helpSupportText: '',
//     });

//     // Initialize safetySecurityData as an empty array to avoid undefined issues
//     const [safetySecurityData, setSafetySecurityData] = useState([]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     useEffect(() => {
//         fetchDriverHelpSupportsEdit();
//         fetchSafetySecurityDetails(); // Fetch safety and security details
//     }, []);

//     const fetchDriverHelpSupportsEdit = async () => {
//         try {
//             const response = await axios.get(
//                 apipath + `/help-support-driver/details/${driverHelpSupportId}`
//             );
//             setFormData(response.data.helpSupportDriver);
//         } catch (error) {
//             console.error("Error fetching driver help & support details:", error);
//         }
//     };

//     const fetchSafetySecurityDetails = async () => {
//         try {
//             const response = await axios.get(
//                 apipath+`/safety-security-driver/details/helpSupport/${driverHelpSupportId}`
//             );
//             setSafetySecurityData(response.data.safetySecurityDrivers || []); // Ensure data is an array
          
//         } catch (error) {
//             console.error("Error fetching safety & security details:", error);
//             setSafetySecurityData([]); // Set an empty array in case of error
//         }
//     };

//     const handleUpdate = async (e) => {
//         e.preventDefault();

//         const response = await fetch(apipath + `/help-support-driver/details/${driverHelpSupportId}`, {
//             method: 'PUT',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(formData),
//         });

//         if (response.status === 200) {
//             iziToast.success({
//                 message: "Driver help & support update successful",
//                 position: "topCenter"
//             });
//             navigate("/driver-help-support");
//         } else {
//             iziToast.error({
//                 message: "Driver help & support update failed",
//                 position: "topCenter"
//             });
//         }
//     };

//     return (
//         <>
//             <SideBar />
//             <Navbar />

//             <section className="home">
//                 <div className="toggle-sidebar" style={{ display: "none" }}>
//                     <i className="bi bi-menu"></i>
//                 </div>

//                 <div className="container pt-3">
//                     <div className="row top-barcolumn">
//                         <h5>Edit driver help & support details</h5>
//                         <div className="row nav-column">
//                             <div>
//                                 <form className="row" onSubmit={handleUpdate}>
//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items1" className="form-label">
//                                             Driver Help & Support Heading
//                                         </label>
//                                         <textarea
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter Driver Help & Support Heading"
//                                             name="helpSupportHeading"
//                                             value={formData.helpSupportHeading}
//                                             onChange={handleChange}
//                                             maxLength={50}
//                                             required
//                                         />
//                                     </div>
//                                     <div className="col-lg-12 mb-3">
//                                         <label htmlFor="nav-items1" className="form-label">
//                                             Driver Help & Support Text
//                                         </label>
//                                         <textarea
//                                             type="text"
//                                             className="form-control"
//                                             id="nav-item1"
//                                             aria-describedby="nav-item-edit"
//                                             placeholder="Enter Driver Help & Support Text"
//                                             name="helpSupportText"
//                                             value={formData.helpSupportText}
//                                             onChange={handleChange}
//                                             maxLength={1000}
//                                             required
//                                         />
//                                     </div>

//                                     {/* Safety & Security details */}
//                                     <div className="col-lg-12 mb-3">
//                                         <h5>Safety & Security Details</h5>
//                                         {/* Only map over safetySecurityData if it exists and is an array */}
//                                         {safetySecurityData && safetySecurityData.length > 0 ? (
//                                             safetySecurityData.map((item, index) => (
//                                                 <div key={index}>
//                                                     <div className="mb-3">
//                                                         <label className="form-label">
//                                                             Safety & Security Heading
//                                                         </label>
//                                                         <textarea
//                                                             type="text"
//                                                             className="form-control"
//                                                             value={item.safetySecurityHeading}
//                                                             readOnly
//                                                         />
//                                                     </div>
//                                                     <div className="mb-3">
//                                                         <label className="form-label">
//                                                             Safety & Security Text
//                                                         </label>
//                                                         <textarea
//                                                             type="text"
//                                                             className="form-control"
//                                                             value={item.SafetySecurityText}
//                                                             readOnly
//                                                         />
//                                                     </div>
//                                                 </div>
//                                             ))
//                                         ) : (
//                                             <p>No Safety & Security details available.</p>
//                                         )}
//                                     </div>

//                                     <div className="">
//                                         <button type="submit" className="btn btn-success">
//                                             Update
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default DriverHelpSupportEdit;


import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const DriverHelpSupportEdit = () => {
    const navigate = useNavigate();
    const { apipath } = useAuth();
    const { driverHelpSupportId } = useParams();
    const [existingSafetySecurityData, setExistingSafetySecurityData] = useState([]);
    const [newSafetySecurityData, setNewSafetySecurityData] = useState([]);

    const [formData, setFormData] = useState({
        helpSupportHeading: '',
        helpSupportText: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSafetySecurityChange = (index, e, isNew) => {
        const { name, value } = e.target;

        if (isNew) {
            const updatedData = [...newSafetySecurityData];
            updatedData[index][name] = value;
            setNewSafetySecurityData(updatedData);
        } else {
            const updatedData = [...existingSafetySecurityData];
            updatedData[index][name] = value;
            setExistingSafetySecurityData(updatedData);
        }
    };

    const handleAddSafetySecurity = () => {
        // Add a new blank entry to the newSafetySecurityData array
        setNewSafetySecurityData([...newSafetySecurityData, { safetySecurityHeading: '', SafetySecurityText: '' }]);
    };

    useEffect(() => {
        fetchDriverHelpSupportsEdit();
        fetchSafetySecurityDetails();
    }, []);

    const fetchDriverHelpSupportsEdit = async () => {
        try {
            const response = await axios.get(
                apipath + `/help-support-driver/details/${driverHelpSupportId}`
            );
            setFormData(response.data.helpSupportDriver);
        } catch (error) {
            console.error("Error fetching driver help & support details:", error);
        }
    };

    const fetchSafetySecurityDetails = async () => {
        try {
            const response = await axios.get(
                apipath+`/safety-security-driver/details/helpSupport/${driverHelpSupportId}`
            );
            setExistingSafetySecurityData(response.data.safetySecurityDrivers || []);
        } catch (error) {
            console.error("Error fetching safety & security details:", error);
            setExistingSafetySecurityData([]);
        }
    };
    
    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            // Update Help & Support Details
            const helpSupportResponse = await fetch(apipath + `/help-support-driver/details/${driverHelpSupportId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (helpSupportResponse.status === 200) {
                iziToast.success({
                    message: "Driver Help & Support update successful",
                    position: "topCenter",
                });
            } else {
                iziToast.error({
                    message: "Failed to update Driver Help & Support",
                    position: "topCenter",
                });
                return; // Stop execution if the Help & Support update fails
            }

            // Only add new Safety & Security entries
            for (let i = 0; i < newSafetySecurityData.length; i++) {
                const entry = newSafetySecurityData[i];
                const safetyAddResponse = await fetch(apipath + '/safety-security-driver/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        safetySecurityHeading: entry.safetySecurityHeading,
                        SafetySecurityText: entry.SafetySecurityText,
                        helpSupport_id: driverHelpSupportId,
                    }),
                });

                if (safetyAddResponse.status === 201) {
                    iziToast.success({
                        message: `Safety & Security entry ${i + 1} added successfully`,
                        position: "topCenter",
                    });
                } else {
                    iziToast.error({
                        message: `Failed to add Safety & Security entry ${i + 1}`,
                        position: "topCenter",
                    });
                }
            }

            // Navigate back to the Help & Support list after successful update
            navigate("/driver-help-support");

        } catch (error) {
            console.error("Error updating Help & Support and Safety & Security:", error);
            iziToast.error({
                message: "An error occurred while updating the details.",
                position: "topCenter",
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Edit driver help & support details</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleUpdate}>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-label">User Help & Support Heading</label>
                                        <textarea
                                            className="form-control"
                                            name="helpSupportHeading"
                                            value={formData.helpSupportHeading}
                                            onChange={handleChange}
                                            maxLength={50}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-label">User Help & Support Text</label>
                                        <textarea
                                            className="form-control"
                                            name="helpSupportText"
                                            value={formData.helpSupportText}
                                            onChange={handleChange}
                                            maxLength={1000}
                                            required
                                        />
                                    </div>

                                    {/* Existing Safety & Security details */}
                                    <div className="col-lg-12 mb-3">
                                        <h5>Existing Safety & Security Details</h5>
                                        {existingSafetySecurityData.length > 0 ? (
                                            existingSafetySecurityData.map((item, index) => (
                                                <div key={index}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Safety & Security Heading</label>
                                                        <textarea
                                                            className="form-control"
                                                            value={item.safetySecurityHeading}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Safety & Security Text</label>
                                                        <textarea
                                                            className="form-control"
                                                            value={item.SafetySecurityText}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No Safety & Security details available.</p>
                                        )}
                                    </div>

                                    {/* New Safety & Security details */}
                                    <div className="col-lg-12 mb-3">
                                        <h5>Add New Safety & Security Details</h5>
                                        {newSafetySecurityData.map((item, index) => (
                                            <div key={index}>
                                                <div className="mb-3">
                                                    <label className="form-label">Safety & Security Heading</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="safetySecurityHeading"
                                                        value={item.safetySecurityHeading}
                                                        onChange={(e) => handleSafetySecurityChange(index, e, true)}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Safety & Security Text</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="SafetySecurityText"
                                                        value={item.SafetySecurityText}
                                                        onChange={(e) => handleSafetySecurityChange(index, e, true)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleAddSafetySecurity}
                                        >
                                            Add Another Safety & Security
                                        </button>
                                    </div>

                                    <div className="mt-3">
                                        <button type="submit" className="btn btn-success">
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DriverHelpSupportEdit;
