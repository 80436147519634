import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";

const UserDetailsEdit = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const { filepath, apipath } = useAuth();
  const { id } = useParams();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: null,
    gender: "",
    address: "",
    city: "",
    state: "",
    country: "",
    profileImg: null,
    status: true,
  });
  const navigate = useNavigate();

  // Handle file input change for profile image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
        setUser({ ...user, profileImg: file });
      };
      reader.readAsDataURL(file);
    }
  };

  // Fetch user details based on user ID
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${apipath}/users/details/${id}`);
      setUser(response.data.user);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // Update user details based on form input
  const handleUpdateUser = async (event) => {
    event.preventDefault();
    // Format the 'dob' to 'YYYY-MM-DD' if it's not null
  const formattedDOB = user.dob ? new Date(user.dob).toISOString().split("T")[0] : null;

  // Create a new user object with the formatted 'dob'
  const updatedUser = { ...user, dob: formattedDOB };

  try {
    const formData = new FormData();
    Object.keys(updatedUser).forEach((key) => {
      formData.append(key, updatedUser[key]);
    });
      await axios.put(`${apipath}/users/details/${id}`, formData);
      iziToast.success({
        message: "User updated successfully",
        position: "topCenter",
      });
      navigate("/usersedit")
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  // Handle input changes for text inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleDateChange = (e) => {
    setUser({ ...user, dob: e.target.value }); // Directly set the value in YYYY-MM-DD format
  };
  

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit User Information</h5>
            <div class="row nav-column">
              <div>
                <form className="row" onSubmit={handleUpdateUser}>
                  <div class="col-lg-6 mb-3">
                    <label for="nav-items1" class="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={user.firstName}
                      onChange={handleInputChange}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={user.lastName}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={user.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                    />
                  </div>

                  {/* Date of Birth */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Date of Birth</label>
                   <input
                    type="date"
                    className="form-control"
                    name="dob"
                    value={user.dob ? user.dob.split("T")[0] : ""} // Splitting the date to get YYYY-MM-DD format
                    onChange={handleDateChange}
                    placeholder="Select DOB"
                  />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-control"
                      name="gender"
                      value={user.gender}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={user.address}
                      onChange={handleInputChange}
                      placeholder="Address"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={user.city}
                      onChange={handleInputChange}
                      placeholder="City"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={user.state}
                      onChange={handleInputChange}
                      placeholder="State"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={user.country}
                      onChange={handleInputChange}
                      placeholder="Country"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Profile Image</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="Profile Preview"
                        style={{ width: "100px", height: "100px", marginTop: "10px" }}
                      />
                    )}
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Status</label>
                    <div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="status"
                          value={true}
                          checked={user.status === true}
                          onChange={() => setUser({ ...user, status: true })}
                        />
                        <label className="form-check-label">Active</label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="status"
                          value={false}
                          checked={user.status === false}
                          onChange={() => setUser({ ...user, status: false })}
                        />
                        <label className="form-check-label">Inactive</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-success">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDetailsEdit;
