import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'; 

const CouponsEdit = () => {
  const dataTableRef = useRef();
  const { apipath } = useAuth();
  const [show, setShow] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedCouponId, setSelectedCouponId] = useState(null);
  const [editCoupon, setEditCoupon] = useState({
    couponName: "",
    couponCode: "",
    couponPrice: "",
    validFrom: "",
    validUntil: "",
    maxUsagePerUser: "",
    couponEnabled: true,
  });

  // Fetch Coupons on Component Mount
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get(apipath + "/coupon-code/details");
        setCoupons(response.data.couponCodeData);
        setLoading(false);

        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } catch (err) {
        setError("Failed to fetch coupons. Please try again.");
        setLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  // Add new coupon
  const addCoupon = async (e) => {
    e.preventDefault();
    try {
      await axios.post(apipath + "/coupon-code/add", editCoupon);
      setCoupons([...coupons, editCoupon]); // Update coupon list
      setShowAdd(false); // Close modal
    } catch (err) {
      setError("Failed to add coupon. Please try again.");
    }
  };

  // Edit coupon logic
  const handleShowEdit = (coupon) => {
    setSelectedCouponId(coupon?._id);
    setEditCoupon({
      couponName: coupon?.couponName,
      couponCode: coupon?.couponCode,
      couponPrice: coupon?.couponPrice,
      validFrom: coupon?.validFrom.split("T")[0],
      validUntil: coupon?.validUntil.split("T")[0],
      maxUsagePerUser: coupon?.maxUsagePerUser,
      couponEnabled: coupon?.couponEnabled,
    });
    setShowEdit(true);
  };

  const updateCoupon = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        apipath + `/coupon-code/details/${selectedCouponId}`,
        editCoupon
      );
      setCoupons(
        coupons?.map((coupon) =>
          coupon?._id === selectedCouponId
            ? { ...coupon, ...editCoupon }
            : coupon
        )
      );
      setShowEdit(false); // Close modal
    } catch (err) {
      setError("Failed to update coupon. Please try again.");
    }
  };

  // Delete coupon logic
  const handleShowDelete = (couponId) => {
    setSelectedCouponId(couponId);
    setShow(true);
  };

  const deleteCoupon = async () => {
    try {
      await axios.delete(apipath + `/coupon-code/details/${selectedCouponId}`);
      setCoupons(coupons.filter((coupon) => coupon?._id !== selectedCouponId));
      setShow(false);
    } catch (err) {
      setError("Failed to delete the coupon. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCoupon((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(""); // Clear error on valid input
  };

  if (loading) return <p>Loading coupons...</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Coupon</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p modal-content1>Are you sure you want to delete this coupon?</p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShow(false)} className="modal-footer-btn">Cancel</button>
            <button onClick={deleteCoupon} className="modal-footer-btn">Delete</button>
          </Modal.Footer>
        </Modal>

        {/* Edit Coupon Modal */}
        <Modal show={showEdit} onHide={() => setShowEdit(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Coupon</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateCoupon}>
              {/* Coupon form fields (same for add/edit) */}
              <div className="mb-3">
                <label className="form-label">Coupon Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="couponName"
                  value={editCoupon.couponName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Coupon Code</label>
                <input
                  className="form-control"
                  type="text"
                  name="couponCode"
                  value={editCoupon.couponCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Coupon Price</label>
                <input
                  className="form-control"
                  type="number"
                  name="couponPrice"
                  value={editCoupon.couponPrice}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Valid From</label>
                <input
                  className="form-control"
                  type="date"
                  name="validFrom"
                  value={editCoupon.validFrom}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Valid Until</label>
                <input
                  className="form-control"
                  type="date"
                  name="validUntil"
                  value={editCoupon.validUntil}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Max Usage Per User</label>
                <input
                  className="form-control"
                  type="number"
                  name="maxUsagePerUser"
                  value={editCoupon.maxUsagePerUser}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Enabled</label>
                <select
                  name="couponEnabled"
                   className="form-control"
                  value={editCoupon.couponEnabled}
                  onChange={handleInputChange}
                >
                  <option value={true}>Enabled</option>
                  <option value={false}>Disabled</option>
                </select>
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="modal-footer">
              <button type="submit" className="modal-footer-btn">Save Changes</button>
              </div>
              
            </form>
          </Modal.Body>
        </Modal>

        {/* Add Coupon Modal */}
        <Modal show={showAdd} onHide={() => setShowAdd(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add New Coupon</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addCoupon}>
              {/* Coupon form fields */}
              <div className="mb-3">
                <label className="form-label">Coupon Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="couponName"
                  value={editCoupon.couponName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Coupon Code</label>
                <input
                  className="form-control"
                  type="text"
                  name="couponCode"
                  value={editCoupon.couponCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Coupon Price</label>
                <input
                  className="form-control"
                  type="number"
                  name="couponPrice"
                  value={editCoupon.couponPrice}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Valid From</label>
                <input
                  className="form-control"
                  type="date"
                  name="validFrom"
                  value={editCoupon.validFrom}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Valid Until</label>
                <input
                  className="form-control"
                  type="date"
                  name="validUntil"
                  value={editCoupon.validUntil}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Max Usage Per User</label>
                <input
                  className="form-control"
                  type="number"
                  name="maxUsagePerUser"
                  value={editCoupon.maxUsagePerUser}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Enabled</label>
                <select
                  name="couponEnabled"
                  className="form-control"
                  value={editCoupon.couponEnabled}
                  onChange={handleInputChange}
                >
                  <option value={true}>Enabled</option>
                  <option value={false}>Disabled</option>
                </select>
              </div>
              {/* {error && <div className="alert alert-danger">{error}</div>} */}
              <div className="modal-footer">
              <button type="submit" className="modal-footer-btn">Add Coupon</button>
              </div>
         
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
          <div className="heading-and-button">
          <h5>Coupon Details</h5>
            <div className="add-button mb-0" style={{marginRight: "-1%"}}>
            <button onClick={() => setShowAdd(true)}>Add New Coupon</button>
            </div>
          </div>
            
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>Coupon Name</th>
                      <th>Coupon Code</th>
                      <th>Coupon Price</th>
                      <th>Valid From</th>
                      <th>Valid Until</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {coupons.map((coupon) => (
                <tr key={coupon._id}>
                  <td>{coupon.couponName}</td>
                  <td>{coupon.couponCode}</td>
                  <td>{coupon.couponPrice}</td>
                  <td>{new Date(coupon.validFrom).toLocaleDateString()}</td>
                  <td>{new Date(coupon.validUntil).toLocaleDateString()}</td>
                        <td>
                          <i
                            className="fa-solid fa-trash mr-2"
                            onClick={() => handleShowDelete(coupon._id)}
                            style={{ cursor: "pointer" }}
                            title="Delete Coupon"
                          ></i>
                          <i
                            onClick={() => handleShowEdit(coupon)}
                            className="fa-solid fa-pen-to-square mr-2"
                            title="Edit Coupon"
                            style={{ cursor: "pointer" }}
                          ></i>
                          {/* <i
                            className="fa-solid user-eye bi-eye"
                            onClick={() => handleShowDelete(coupon._id)}
                            style={{ cursor: "pointer" }}
                            title="View Details"
                          ></i> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CouponsEdit;
