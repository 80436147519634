import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { Modal } from "react-bootstrap";

const DriverHelpSupportDetails = () => {
  const [show, setShow] = useState(false);
  const [driverHelpSupportIdToDelete, setDriverHelpSupportIdToDelete] =
    useState(null);

  const handleShow = (id) => {
    setDriverHelpSupportIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const navigate = useNavigate();
  const [driverHelpSupports, setDriverHelpSupports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();

  const dataTableRef = useRef();

  useEffect(() => {
    fetchDriverHelpSupports();
    if (driverHelpSupports.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [driverHelpSupports]);

  const fetchDriverHelpSupports = async () => {
    try {
      const response = await axios.get(
        apipath + "/help-support-driver/details"
      );
      setDriverHelpSupports(response.data.helpSupportDriver);
    } catch (error) {
      console.error("Error fetching driver helpSupport:", error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        apipath + `/help-support-driver/details/${driverHelpSupportIdToDelete}`
      );
      await fetchDriverHelpSupports(); // Refresh courses after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting help-support-driver:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete driver help & support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this driver help & support?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-footer-btn"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>Driver Help & Support Details</h5>
              <div className="add-button mb-0" style={{marginRight: "7.5%"}}>
                <Link to="/add-driver-help-support">
                  <button>+ Add Driver Help & Support</button>
                </Link>
              </div>
            </div>

            <div className="col-lg-11 maintable-column">
              <div className="container mt-4">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Driver Help & Support Heading</th>
                      <th>Driver Help & Support Text</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {driverHelpSupports.map((driverHelpSupport, index) => (
                      <tr key={driverHelpSupport._id}>
                        <td className="text-center">{index + 1}</td>
                        <td>{driverHelpSupport.helpSupportHeading}</td>
                        <td>{driverHelpSupport.helpSupportText}</td>
                        <td>
                          <Link
                            to={`/edit-driver-help-support/${driverHelpSupport._id}`}
                          >
                            <i className="fa-regular fa-pen-to-square"></i>
                          </Link>{" "}
                          &nbsp;
                          <i
                            className="fa-solid fa-trash"
                            onClick={() => handleShow(driverHelpSupport._id)}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DriverHelpSupportDetails;
