import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";

const Aboutus = () => {
  const [content, setContent] = useState("");
  const { apipath } = useAuth();

  useEffect(() => {
    const fetchAboutus = async () => {
      try {
        const response = await axios.get(`${apipath}/aboutus`);
        setContent(response.data.aboutus.content || "");
      } catch (error) {
        console.error("Error fetching Aboutus:", error);
      }
    };

    fetchAboutus();
  }, []);

  return (
    <>
      {/* <div class="container maintenance-message">
                <div class="container marquee-content">
                    Etorides <span class="highlight">E-Rishkaw</span> <br />Support Email us : <br /> rideseto@gmail.com
                </div>
            </div> */}
            <div>
              <img src="/imgs/logo.jpeg" height={"45px"} alt={"logo"} style={{padding: "5px"}}/>
            </div>
      <div class="container-from-text">
        <div
          className="terms-content"
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </>
  );
};

export default Aboutus;
