import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import iziToast from "izitoast";

// Google Maps configuration
const mapContainerStyle = {
  width: "100%",
  height: "90vh",
  position: "relative",
};

const LiveMap = () => {
  const [driverPosition, setDriverPosition] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [directions, setDirections] = useState(null); // State to store directions
  const { id } = useParams();
  const { apipath } = useAuth();

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const bookingResponse = await axios.get(apipath + `/booking-service/details/${id}`);
        const booking = bookingResponse.data.booking;

        const { user_id, driverId } = booking;

        // Fetch user details
        const userResponse = await axios.get(apipath + `/users/details/${user_id}`);
        const user = userResponse.data.user;
        if (user && user.latitude && user.longitude) {
          setUserPosition({ lat: user.latitude, lng: user.longitude });
        } else {
          iziToast.error({
            title: "Error",
            message: "User not found or has invalid location.",
            position: "topRight",
          });
        }

        // Fetch driver details
        const driverResponse = await axios.get(apipath + `/users/details/${driverId}`);
        const driver = driverResponse.data.user;
        if (driver && driver.latitude && driver.longitude) {
          setDriverPosition({ lat: driver.latitude, lng: driver.longitude });
        } else {
          iziToast.error({
            title: "Error",
            message: "Driver not found or has invalid location.",
            position: "topRight",
          });
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching details:", error);
        iziToast.error({
          title: "Error",
          message: "Failed to fetch booking details.",
          position: "topRight",
        });
        setIsLoading(false);
      }
    };

    fetchBookingDetails();
  }, [id, apipath]);

  const handleMapLoad = (map) => {
    if (window.google && window.google.maps && window.google.maps.marker) {
      // Driver marker
      if (driverPosition) {
        new window.google.maps.marker.AdvancedMarkerElement({
          map,
          position: driverPosition,
          title: "Driver",
        });
      }

      // User marker
      if (userPosition) {
        new window.google.maps.marker.AdvancedMarkerElement({
          map,
          position: userPosition,
          title: "User",
        });
      }
    } else {
      console.error("Google Maps AdvancedMarkerElement is not available.");
    }

    // Calculate and show route
    if (driverPosition && userPosition) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: driverPosition,
          destination: userPosition,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error("Error fetching directions:", status);
          }
        }
      );
    }
  };

  const googleMapId = process.env.REACT_APP_GOOGLE_MAP_ID;

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="mt-4">
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
        libraries={["marker"]} // Explicitly load the marker library
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={driverPosition || userPosition}
          zoom={12}
          onLoad={handleMapLoad}
          options={{
            mapId: googleMapId, // Add your Map ID here
          }}
        >
          {/* Render the directions if available */}
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default LiveMap;
