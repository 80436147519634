import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';
import iziToast from 'izitoast';

const ETOCoinAndDiscountEdit = () => {
    const navigate = useNavigate();
  const { apipath } = useAuth();
  const { etoId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    etoCoin: '',
    distancePrice: '',
    rideBookingFee: '',
  });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      useEffect(() => {
  const fetchEto = async () => {
    try {
      const response = await axios.get(`${apipath}/coins-distance-price/details/${etoId}`);
      const data = response.data.coinsADistancePrice;
      setFormData({
        etoCoin: data.etoCoin || '',
        distancePrice: data.distancePrice || '',
        rideBookingFee: data.rideBookingFee || '',
      });
    } catch (error) {
      console.error('Error fetching eto:', error);
    }
  };
  fetchEto();
}, [apipath, etoId]);

  // Handle form submission to update quiz question and options
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      // API call to update the quiz question and options
      const response = await axios.put(`${apipath}/coins-distance-price/details/${etoId}`, formData);
      if (response.status === 200) {
        iziToast.success({ title: "Success", message: "Eto coin & discount updated successfully!" });
        navigate("/rto-coin-discount"); // Redirect after successful update
      }
    } catch (error) {
      iziToast.error({ title: "Error", message: "Failed to update eto coin & discount." });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Eto Coin & Discount</h5>
            <div className="row nav-column">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-12 mb-3">
                  <label className="form-label">Eto Coin</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter eto coin"
                    name="etoCoin"
                    value={formData.etoCoin}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-lg-12 mb-3">
                  <label className="form-label">Distance Price</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter distance price"
                    name="distancePrice"
                    value={formData.distancePrice}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-lg-12 mb-3">
                  <label className="form-label">Ride Service Fee</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter ride booking fee"
                    name="rideBookingFee"
                    value={formData.rideBookingFee}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <Link to="/rto-coin-discount">
                    <button className="btn btn-primary">Back</button>
                  </Link>
                  <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? "Updating..." : "Update Eto Coin & Discount"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ETOCoinAndDiscountEdit;
