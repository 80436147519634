import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";

const UserHelpSupportAdd = () => {
    const navigate = useNavigate();
    const { apipath } = useAuth();

    const [formData, setFormData] = useState({
        helpSupportHeading: '',
        helpSupportText: '',
    });

    // State to store multiple safety & security data
    const [safetySecurityData, setSafetySecurityData] = useState([{
        safetySecurityHeading: '',
        SafetySecurityText: ''
    }]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSafetySecurityChange = (index, e) => {
        const { name, value } = e.target;
        const updatedData = [...safetySecurityData];
        updatedData[index][name] = value;
        setSafetySecurityData(updatedData);
    };

    const handleAddSafetySecurity = () => {
        setSafetySecurityData([...safetySecurityData, { safetySecurityHeading: '', SafetySecurityText: '' }]);
    };


    const handleRegister = async (e) => {
        e.preventDefault();
        try {
        const helpSupportResponse = await fetch(apipath + '/help-support/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

         // Parse the response
         const helpSupportData = await helpSupportResponse.json();
         console.log('Help Support Response:', helpSupportData);

        // Adjust the key to match the correct API response
        if (helpSupportResponse.status === 201 && helpSupportData?.helpSupport?._id) {
            const helpSupportId = helpSupportData.helpSupport._id; // Retrieve the _id from helpSupportDriver

            iziToast.success({
                message: "Driver help & support added successfully",
                position: "topCenter"
            });

            // Save each safety & security entry with the dynamically retrieved helpSupportId
            for (let i = 0; i < safetySecurityData.length; i++) {
                const entry = safetySecurityData[i];
                const safetyResponse = await fetch(apipath + '/safety-security/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        safetySecurityHeading: entry.safetySecurityHeading,
                        SafetySecurityText: entry.SafetySecurityText,
                        helpSupport_id: helpSupportId // Use the dynamic ID from the previous response
                    }),
                });

                if (safetyResponse.status === 201) {
                    iziToast.success({
                        message: `Safety & Security entry ${i + 1} added successfully`,
                        position: "topCenter"
                    });
                } else {
                    iziToast.error({
                        message: `Failed to add Safety & Security entry ${i + 1}`,
                        position: "topCenter"
                    });
                }
            }

            // After successful completion, navigate back to the help & support list page
            navigate("/user-help-support");
        } else {
            iziToast.error({
                message: "user help & support add failed",
                position: "topCenter"
            });
        }
    } catch (error) {
        iziToast.error({
            message: "An error occurred while adding user help & support",
            position: "topCenter"
        });
        console.error("Error adding user help & support:", error);
    }
};

    return (
        <>
            <SideBar />
            <Navbar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>

                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <h5>Add user help & support details</h5>
                        <div className="row nav-column">
                            <div>
                                <form className="row" onSubmit={handleRegister}>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        User Help & Support Heading
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter User Help & Support Heading"
                                            name="helpSupportHeading"
                                            value={formData.helpSupportHeading}
                                            onChange={handleChange}
                                            maxLength={50}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label for="nav-items1" className="form-label">
                                        User Help & Support Text
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="nav-item1"
                                            aria-describedby="nav-item-edit"
                                            placeholder="Enter User Help & Support Text"
                                            name="helpSupportText"
                                            value={formData.helpSupportText}
                                            onChange={handleChange}
                                            maxLength={1000}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    {/* Safety & Security Section */}
                                    {safetySecurityData.map((data, index) => (
                                        <div key={index} className="col-lg-12 mb-3">
                                            <h6>Safety & Security {index + 1}</h6>
                                            <label htmlFor={`safetyHeading-${index}`} className="form-label">
                                                Safety & Security Heading
                                            </label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Enter Safety & Security Heading"
                                                name="safetySecurityHeading"
                                                value={data.safetySecurityHeading}
                                                onChange={(e) => handleSafetySecurityChange(index, e)}
                                                maxLength={100}
                                                required
                                            />
                                            <label htmlFor={`safetyText-${index}`} className="form-label mt-2">
                                                Safety & Security Text
                                            </label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Enter Safety & Security Text"
                                                name="SafetySecurityText"
                                                value={data.SafetySecurityText}
                                                onChange={(e) => handleSafetySecurityChange(index, e)}
                                                maxLength={1000}
                                                required
                                            />
                                        </div>
                                    ))}

                                    <div className="mb-3">
                                        <button type="button" className="btn btn-secondary" onClick={handleAddSafetySecurity}>
                                            Add Another Safety & Security
                                        </button>
                                    </div>

                                    <div className="">
                                        <button type="submit" className="btn btn-success">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default UserHelpSupportAdd;
