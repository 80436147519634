// import React, { useEffect, useState } from "react";
// import SideBar from "./SideBar";
// import Navbar from "./Navbar";
// import { useAuth } from "../AuthContext";
// import { useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import axios from "axios";

// const DriverDetailsViewEdit = () => {
//   const navigate = useNavigate();
//   const { apipath, filepath } = useAuth();
//   const { driverId } = useParams();
//   const [isLoading, setIsLoading] = useState(false);

//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phone: '',
//     latitude: '',
//     longitude: '',
//     status: '',
//     role: '',
//     licenseImg: '',
//     drivingLicStatus: false,
//     profileImg: '',
//     profileStatus: false,
//     aadhaarImg: '',
//     aadhaarStatus: false,
//     panImg: '',
//     panStatus: false,
//     rcImg: '',
//     rcStatus: false,
//     viImg: '',
//     vehicalInsStatus: false,
//     vpImg: '',
//     vehicalPerStatus: false,
//     profilePreview: null,
//     licenseImgPreview: null,
//     aadhaarImgPreview: null,
//   });

//   // Handle file input and text input changes
//   const handleChange = (e) => {
//     const { name, value, type } = e.target;
//     if (type === 'file') {
//       const file = e.target.files[0];
//       setFormData({
//         ...formData,
//         [name]: file,
//         [`${name}Preview`]: URL.createObjectURL(file),
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   // Fetch driver details on component mount
//   useEffect(() => {
//     const fetchDriverDetails = async () => {
//       try {
//         const response = await axios.get(`${apipath}/users/details/${driverId}`);
//         const data = response.data.user;

//         setFormData({
//           ...formData,
//           firstName: data.firstName || '',
//           lastName: data.lastName || '',
//           email: data.email || '',
//           phone: data.phone || '',
//           latitude: data.latitude || '',
//           longitude: data.longitude || '',
//           status: data.status || '',
//           role: data.role || '',
//           licenseImg: data.licenseImg || '',
//           drivingLicStatus: data.drivingLicStatus || false,
//           profileImg: data.profileImg || '',
//           profileStatus: data.profileStatus || false,
//           aadhaarImg: data.aadhaarImg || '',
//           aadhaarStatus: data.aadhaarStatus || false,
//           panImg: data.panImg || '',
//           panStatus: data.panStatus || false,
//           rcImg: data.rcImg || '',
//           rcStatus: data.rcStatus || false,
//           viImg: data.viImg || '',
//           vehicalInsStatus: data.vehicalInsStatus || false,
//           vpImg: data.vpImg || '',
//           vehicalPerStatus: data.vehicalPerStatus || false,
//         });
//       } catch (error) {
//         console.error("Error fetching driver profile details:", error);
//       }
//     };

//     fetchDriverDetails();
//   }, [apipath, driverId]);

//   const handleUploadButtonClick = (inputId) => {
//     document.getElementById(inputId).click();
//   };

//   const handleToggleStatus = async (driverId, field, currentValue) => {
//     try {
//       setIsLoading(true);

//       // Prepare update data
//       const updateData = { [field]: !currentValue };
//       console.log('Updating with data:', updateData);

//       // Send the update request
//       const response = await axios.put(`${apipath}/users/details/${driverId}`, updateData);

//       console.log('API Response:', response);

//       if (response.status === 200) {
//         // Update local state with the new value
//         setFormData(prevData => ({
//           ...prevData,
//           [field]: !currentValue
//         }));

//         iziToast.success({
//           message: "Driver document update successful",
//           position: "topCenter",
//         });
//       } else {
//         iziToast.error({
//           message: "Driver document update failed",
//           position: "topCenter",
//         });
//       }
//     } catch (error) {
//       console.error(`Error updating ${field} status:`, error.response || error.message);

//       iziToast.error({
//         message: "An error occurred",
//         position: "topCenter",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <>
//       <SideBar />
//       <Navbar />

//       <section className="home">
//         <div className="toggle-sidebar" style={{ display: "none" }}>
//           <i className="bi bi-menu"></i>
//         </div>

//         <div className="container pt-3">
//           <div className="row top-barcolumn">
//             <h5>Edit and verify driver details</h5>
//             <div className="row nav-column">
//               <div>
//                 <form className="row">
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item1" className="form-label">
//                       First Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item1"
//                       placeholder="Enter first name"
//                       name="firstName"
//                       value={formData.firstName}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                       Last Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item2"
//                       placeholder="Enter last name"
//                       name="lastName"
//                       value={formData.lastName}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>

//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item1" className="form-label">
//                       Email
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="nav-item1"
//                       placeholder="Enter email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                       Phone Number
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item2"
//                       placeholder="Enter phone number"
//                       name="phone"
//                       value={formData.phone}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>

//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item1" className="form-label">
//                       Latitude
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item1"
//                       placeholder="Enter latitude"
//                       name="latitude"
//                       value={formData.latitude}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                       Longitude
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item2"
//                       placeholder="Enter longitude"
//                       name="longitude"
//                       value={formData.longitude}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>

//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item1" className="form-label">
//                       Driver Status
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item1"
//                       placeholder="Enter status"
//                       name="status"
//                       value={formData.status}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                       readOnly
//                     />
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                       Role
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="nav-item2"
//                       placeholder="Enter role"
//                       name="role"
//                       value={formData.role}
//                       onChange={handleChange}
//                       maxLength={50}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.profilePreview ||
//                           (formData.profileImg && typeof formData.profileImg === 'string'
//                             ? `${formData.profileImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>Profile Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="profileImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//   <label className="form-label">Profile Status</label><br />
//   <label className="switch">
//     <input
//       type="checkbox"
//       checked={formData.profileStatus}
//       onChange={() => handleToggleStatus(driverId, 'profileStatus', formData.profileStatus)}
//     />
//     <span className="slider round"></span>
//   </label>
// </div>

//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.licenseImgPreview ||
//                           (formData.licenseImg && typeof formData.licenseImg === 'string'
//                             ? `${formData.licenseImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>license Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="licenseImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//   <label htmlFor="nav-item2" className="form-label">Driving License Status</label><br />
//   <label className="switch">
//     <input
//       type="checkbox"
//       checked={formData.drivingLicStatus}
//       onChange={() => handleToggleStatus(driverId, 'drivingLicStatus', formData.drivingLicStatus)}
//     />
//     <span className="slider round"></span>
//   </label>
// </div>

//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.aadhaarImgPreview ||
//                           (formData.aadhaarImg && typeof formData.aadhaarImg === 'string'
//                             ? `${formData.aadhaarImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>Aadhaar Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="aadhaarImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//   <label htmlFor="nav-item2" className="form-label">Aadhaar Status</label><br />
//   <label className="switch">
//     <input
//       type="checkbox"
//       checked={formData.aadhaarStatus}
//       onChange={() => handleToggleStatus(driverId, 'aadhaarStatus', formData.aadhaarStatus)}
//     />
//     <span className="slider round"></span>
//   </label>
// </div>

//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.panImgPreview ||
//                           (formData.panImg && typeof formData.panImg === 'string'
//                             ? `${formData.panImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>Pan card Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="panImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                       Pan Card Status
//                     </label><br />
//                     <label className="switch">
//                       <input
//                         type="checkbox"
//                       checked={formData.panStatus}
//                       onChange={() => handleToggleStatus(driverId,'panStatus', !formData.panStatus)}
//                       />
//                       <span className="slider round"></span>
//                     </label>
//                   </div>

//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.rcImgPreview ||
//                           (formData.rcImg && typeof formData.rcImg === 'string'
//                             ? `${formData.rcImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>Registration Certificate (RC) Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="rcImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                     Registration Certificate (RC) Status
//                     </label><br />
//                     <label className="switch">
//                       <input
//                         type="checkbox"
//                       checked={formData.rcStatus}
//                       onChange={() => handleToggleStatus(driverId, 'rcStatus',!formData.rcStatus)}
//                       />
//                       <span className="slider round"></span>
//                     </label>
//                   </div>

//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.viImgPreview ||
//                           (formData.viImg && typeof formData.viImg === 'string'
//                             ? `${formData.viImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>Vehicle Insurance Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="viImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                     Vehicle Insurance Status
//                     </label><br />
//                     <label className="switch">
//                       <input
//                         type="checkbox"
//                       checked={formData.vehicalInsStatus}
//                       onChange={() => handleToggleStatus(driverId,'vehicalInsStatus', !formData.vehicalInsStatus)}
//                       />
//                       <span className="slider round"></span>
//                     </label>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <div className="change-profile pt-4 pb-4">
//                       <img
//                         src={
//                           formData.vpImgPreview ||
//                           (formData.vpImg && typeof formData.vpImg === 'string'
//                             ? `${formData.vpImg}`
//                             : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)
//                         }
//                         width={90}
//                         height={90}
//                         style={{ borderRadius: '35px' }}
//                         alt="profile-img"
//                         onClick={handleUploadButtonClick}
//                       />
//                       <h6>Vehicle Permit Image</h6>
//                       <input
//                         hidden
//                         type="file"
//                         id="fileInput"
//                         name="vpImg"
//                         onChange={handleChange}
//                         style={{ display: 'none' }}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 mb-3">
//                     <label htmlFor="nav-item2" className="form-label">
//                     Vehicle Permit Status
//                     </label><br />
//                     <label className="switch">
//                       <input
//                         type="checkbox"
//                       checked={formData.vehicalPerStatus}
//                       onChange={() => handleToggleStatus(driverId,'vehicalPerStatus', !formData.vehicalPerStatus)}
//                       />
//                       <span className="slider round"></span>
//                     </label>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default DriverDetailsViewEdit;

import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";
import { Image, Modal } from "react-bootstrap"

const DriverDetailsViewEdit = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const { driverId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [imageToShow, setImageToShow] = useState(null); // Image to show in modal

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    latitude: "",
    longitude: "",
    status: "",
    role: "",
    licenseImg: "",
    drivingLicStatus: false,
    profileImg: "",
    profileStatus: false,
    aadhaarImg: "",
    aadhaarStatus: false,
    panImg: "",
    panStatus: false,
    rcImg: "",
    rcStatus: false,
    viImg: "",
    vehicalInsStatus: false,
    vpImg: "",
    vehicalPerStatus: false,
    profilePreview: null,
    licenseImgPreview: null,
    aadhaarImgPreview: null,
    panImgPreview: null,
    rcImgPreview: null,
    viImgPreview: null,
    vpImgPreview: null,
  });

  // Handle file input and text input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: file,
        [`${name}Preview`]: URL.createObjectURL(file),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      // Check if the status field is being updated
      if (name === "status") {
        const newStatus = value === "Active"; // Convert the value to boolean
        setFormData({
          ...formData,
          [name]: newStatus, // Update the status in the formData
        });
    
        // Optionally, call handleToggleStatus here if you want to update immediately
        handleToggleStatus("status", newStatus); // Pass the new status to the toggle function
      } else {
        setFormData({
          ...formData,
          [name]: value, // Update other fields normally
        });
      }
    }
  };

  // Fetch driver details on component mount
  useEffect(() => {
    const fetchDriverDetails = async () => {
      try {
        const response = await axios.get(
          `${apipath}/users/details/${driverId}`
        );
        const data = response.data.user;

        setFormData((prevData) => ({
          ...prevData,
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          email: data.email || "",
          phone: data.phone || "",
          latitude: data.latitude || "",
          longitude: data.longitude || "",
          status: data.status || "",
          role: data.role || "",
          licenseImg: data.licenseImg || "",
          drivingLicStatus: data.drivingLicStatus || false,
          profileImg: data.profileImg || "",
          profileStatus: data.profileStatus || false,
          aadhaarImg: data.aadhaarImg || "",
          aadhaarStatus: data.aadhaarStatus || false,
          panImg: data.panImg || "",
          panStatus: data.panStatus || false,
          rcImg: data.rcImg || "",
          rcStatus: data.rcStatus || false,
          viImg: data.viImg || "",
          vehicalInsStatus: data.vehicalInsStatus || false,
          vpImg: data.vpImg || "",
          vehicalPerStatus: data.vehicalPerStatus || false,
        }));
      } catch (error) {
        console.error("Error fetching driver profile details:", error);
      }
    };

    fetchDriverDetails();
  }, [apipath, driverId]);

  // Function to open the modal when the image is clicked
  const handleImageClick = (imageSrc) => {
    setImageToShow(imageSrc);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
    setImageToShow(null);
  };

  const handleUploadButtonClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleToggleStatus = async (field, newStatus) => {
    try {
      setIsLoading(true);

      // Prepare update data
      const updateData = { [field]: newStatus }; // Use the new status directly

      console.log("Updating with data:", updateData);

      // Send the update request
      const response = await axios.put(
        `${apipath}/users/details/${driverId}`,
        updateData
      );

      console.log("API Response:", response);

      if (response.status === 200) {
        // Update local state with the new value
        setFormData((prevData) => ({
          ...prevData,
          [field]: !formData[field],
        }));

        iziToast.success({
          message: "Driver document update successful",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Driver document update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error(
        `Error updating ${field} status:`,
        error.response || error.message
      );

      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (url, filename) => {
    // Check if the image is from the same origin or cross-origin
    fetch(url, {
      mode: "cors",
    })
      .then((response) => response.blob()) // Convert the image to a blob
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob); // Create a temporary URL for the blob
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", filename); // Set the filename
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); // Clean up the temporary blob URL
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

   // Function to handle status update in the backend
   const handleStatusUpdate = async (status) => {
    try {
      setIsLoading(true);

      const updateData = { status: status === "Active" };
      console.log(updateData)
      const response = await axios.put(
        `${apipath}/users/details/${driverId}`,
        updateData
      );

      if (response.status === 200) {
        iziToast.success({
          message: "Driver status updated successfully",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Driver status update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error updating driver status:", error);
      iziToast.error({
        message: "An error occurred while updating the status",
        position: "topCenter",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit and verify driver details</h5>
            <div className="row nav-column">
              <form className="row">
                {/* Fields */}
                {[
                  { label: "First Name", name: "firstName", type: "text" },
                  { label: "Last Name", name: "lastName", type: "text" },
                  { label: "Email", name: "email", type: "email" },
                  { label: "Phone Number", name: "phone", type: "text" },
                  { label: "Latitude", name: "latitude", type: "text" },
                  { label: "Longitude", name: "longitude", type: "text" },
                  { label: "Driver Status", name: "status", type: "select" },
                  { label: "Role", name: "role", type: "text" },
                ].map((field) => (
                  <div className="col-lg-6 mb-3" key={field.name}>
                    <label className="form-label">{field.label}</label>
                    {field.name === "status" ? (
                      <select
                        className="form-control"
                        name={field.name}
                        value={formData[field.name] ? "Active" : "Inactive"} 
                        onChange={handleChange}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    ) : (
                      <input
                        type={field.type}
                        className="form-control"
                        placeholder={`Enter ${field.label.toLowerCase()}`}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        readOnly={field.name !== "role"}
                      />
                    )}
                  </div>
                ))}

                {/* Document Fields */}
                {[
                  {
                    label: "Profile Image",
                    name: "profileImg",
                    preview: "profilePreview",
                    statusField: "profileStatus",
                  },
                  {
                    label: "License Image",
                    name: "licenseImg",
                    preview: "licenseImgPreview",
                    statusField: "drivingLicStatus",
                  },
                  {
                    label: "Aadhaar Image",
                    name: "aadhaarImg",
                    preview: "aadhaarImgPreview",
                    statusField: "aadhaarStatus",
                  },
                  {
                    label: "Pan card Image",
                    name: "panImg",
                    preview: "panImgPreview",
                    statusField: "panStatus",
                  },
                  {
                    label: "Registration Certificate (RC) Image",
                    name: "rcImg",
                    preview: "rcImgPreview",
                    statusField: "rcStatus",
                  },
                  {
                    label: "Vehicle Insurance Image",
                    name: "viImg",
                    preview: "viImgPreview",
                    statusField: "vehicalInsStatus",
                  },
                  {
                    label: "Vehicle Permit Image",
                    name: "vpImg",
                    preview: "vpImgPreview",
                    statusField: "vehicalPerStatus",
                  },
                ].map((doc) => (
                  <div className="col-lg-6 mb-3" key={doc.name}>
                    <div className="change-profile pt-4 pb-2">
                      <div>
                        <img
                          src={
                            formData[doc.preview] ||
                            (formData[doc.name]
                              ? `${formData[doc.name]}`
                              : `${process.env.PUBLIC_URL}imgs/user-icon.png`)
                          }
                          width={90}
                          height={90}
                          style={{ borderRadius: "35px", cursor: "pointer" }}
                          alt={`${doc.label.toLowerCase()}-img`}
                          onClick={() =>
                            handleImageClick(
                              formData[doc.preview] || formData[doc.name]
                            )
                          }
                        />
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <h6 className="mb-0">{doc.label}</h6>
                          <i
                            className="bi bi-download "
                            onClick={() =>
                              handleDownload(
                                formData[doc.name],
                                `${doc.label}.jpg`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <label className="form-label">{doc.label} Status</label>
                    {/* <br /> */}{" "}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData[doc.statusField]}
                        onChange={() => handleToggleStatus(doc.statusField)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                ))}
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for image view */}
      <Modal
        show={isModalOpen}
        onHide={handleModalClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
        
          <Modal.Title>Preview Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={imageToShow} alt="Preview" fluid />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DriverDetailsViewEdit;
